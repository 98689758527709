import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import favicon from '../../static/assets/semikolon_round.png'
import GlobalStyles from '../GlobalStyles'
import Footer from './Footer'
import Header from './Header'

export default function Layout({ children, pageTitle }) {
  return (
    <StaticQuery
      query={graphql`
        query helmetQuery {
          site {
            siteMetadata {
              description
              title
              author
              keywords
            }
          }
        }
      `}
      render={data => (
        <>
          <GlobalStyles />
          <LayoutStyle id="page-top">
            <Helmet>
              <html lang="en" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
              <title>
                {data.site.siteMetadata.title} | {`${pageTitle}`}
              </title>
              <meta
                name="description"
                content={data.site.siteMetadata.description}
              />
              <meta
                name="keywords"
                content={data.site.siteMetadata.keywords.join(',')}
              />
              <link rel="icon" href={favicon} />
            </Helmet>
            <Header />
            {children}
            <Footer />
          </LayoutStyle>
        </>
      )}
    />
  )
}

const LayoutStyle = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  color: var(--primary-darkgray);
  font-weight: lighter;
  min-height: 100vh;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 0 auto;
`
