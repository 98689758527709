import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components/macro'
import { IoMdArrowDropup } from 'react-icons/io'

export default function Footer(props) {
  return (
    <FooterStyled>
      <AnchorToTop href="#page-top">
        <span>back to top</span> <IoMdArrowDropup size="16" />
      </AnchorToTop>
      <FooterContentStyled>
        <div>
          &copy; Rebecca Frey {new Date().getFullYear()}, created with &hearts;
          and{' '}
          <a
            href="https://www.gatsbyjs.com/"
            rel="noreferrer"
            title="The best place to find out more about the React-based open-source framework"
          >
            Gatsby
          </a>
        </div>
        <div>
          <LegalNoticeLinkStyled to="/impressum">
            Impressum
          </LegalNoticeLinkStyled>
          <LegalNoticeLinkStyled to="/datenschutz">
            Datenschutz
          </LegalNoticeLinkStyled>
        </div>
      </FooterContentStyled>
    </FooterStyled>
  )
}

const AnchorToTop = styled.a`
  display: flex;
  justify-self: center;
  background: var(--secondary-darkgray);
  border-radius: 4px;
  color: var(--secondary-light);
  margin: 8px 0;
  padding: 4px 8px;
  vertical-align: middle;

  :visited {
    color: var(--secondary-light);
  }
`
const FooterStyled = styled.footer`
  display: grid;
  font-size: 1.2rem;
  height: 9.6rem;
  padding: 16px 3%;
  position: sticky;
  text-align: center;
`
const FooterContentStyled = styled.div`
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 650px) {
    display: flex;
    justify-content: space-between;
  }
`
const LegalNoticeLinkStyled = styled(Link)`
  padding-right: 8px;
`
