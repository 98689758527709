import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components/macro'
import GoldenOval from '../img/GoldenOval_prog.jpg'

const NavLink = props => (
  <NavItemStyled>
    <NavLinkStyled to={props.to} activeClassName="active">
      {props.children}
    </NavLinkStyled>
  </NavItemStyled>
)

const PartiallyActiveNavLink = props => (
  <NavItemStyled>
    <NavLinkStyled
      to={props.to}
      partiallyActive={true}
      activeClassName="active"
    >
      {props.children}
    </NavLinkStyled>
  </NavItemStyled>
)

export default function Header() {
  return (
    <HeaderStyled>
      <WebsitenameStyled>
        <h1>Rebecca Frey</h1>
        <p>Coden mit Seele. Soul Coding.</p>
      </WebsitenameStyled>
      <NavStyled>
        <NavLink exact to="/">
          Home
        </NavLink>
        <PartiallyActiveNavLink to="/about/">About</PartiallyActiveNavLink>
        <PartiallyActiveNavLink to="/blog/">Blog</PartiallyActiveNavLink>
        <PartiallyActiveNavLink to="/contact/">Contact</PartiallyActiveNavLink>
      </NavStyled>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.header`
  display: block;
  align-items: center;
  justify-content: space-between;
  color: var(--primary-dark);
  margin: 24px 12px;
  text-align: left;
`
const NavItemStyled = styled.li`
  display: inline-flex;
  text-align: right;
`
const NavStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid var(--primary-light);
  border-bottom: 1px solid var(--primary-light);
  height: 48px;
  list-style: none;
`
const NavLinkStyled = styled(Link)`
  padding: 8px;

  &.active {
    background-image: url(${GoldenOval});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 48px;
    color: var(--primary-dark);
  }
`
const WebsitenameStyled = styled.div`
  display: inline;
`
